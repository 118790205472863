import React from 'react'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import HashLoader from 'react-spinners/HashLoader'

export default function blockList({
  blocks,
  isModelInSeason,
  hourlyData,
  modelLogic,
  setBlock,
  blockId,
  isLoading,
}) {
  return (
    <ul className="relative z-0 p-1 overflow-y-scroll border divide-y divide-gray-200 rounded-md max-h-96">
      {blocks.map((block) => {
        let mData = null
        const isActive = Boolean(block.startDate)
        let percentStyletPenetration = null
        if (isActive && hourlyData.length !== 0) {
          mData = modelLogic(isModelInSeason, hourlyData, block)

          if (mData && mData.mData.length !== 0) {
            percentStyletPenetration = Math.round(
              mData.mData.slice(-1)[0].percentStyletPenetration,
            )
          }
        }
        return (
          <button
            key={block.id}
            onClick={() => setBlock(block.id)}
            className={`w-full block rounded-md focus:outline-none focus-within:ring-2 focus-within:ring-inset focus-within:ring-secondary-500 ${
              block.id === blockId ? `bg-gray-100` : `bg-white`
            }`}
          >
            <div className="relative z-0 px-2 py-3 rounded-md hover:bg-gray-100">
              <div className="flex items-center justify-between">
                <p className="flex-1 mr-2 text-sm font-extrabold text-left text-gray-600 truncate">
                  {isLoading ? `...` : block.blockName}
                </p>
                {isActive ? (
                  <div className="flex items-center justify-center w-14 h-14">
                    <CircularProgressbarWithChildren value={percentStyletPenetration}>
                      {percentStyletPenetration === null ? (
                        <HashLoader
                          size={16}
                          color={'#1987C2'}
                          loading={isLoading}
                        />
                      ) : (
                        <span
                          className={`text-secondary-800 font-extrabold text-xs`}
                        >{`${(percentStyletPenetration =
                          percentStyletPenetration > 250
                            ? '250+'
                            : percentStyletPenetration)}%`}</span>
                      )}
                    </CircularProgressbarWithChildren>
                  </div>
                ) : (
                  <span
                    className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                    Inactive
                  </span>
                )}
              </div>
              <div className="mt-2 space-x-1">
                {block.firstSpray && (
                  <span
                    className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium text-gray-800 border border-gray-300 ${
                      block.id === blockId ? `bg-white` : `bg-gray-100`
                    }`}
                  >
                    1st Spray
                  </span>
                )}
                {block.secondSpray && (
                  <span
                    className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium text-gray-800 border border-gray-300 ${
                      block.id === blockId ? `bg-white` : `bg-gray-100`
                    }`}
                  >
                    2nd Spray
                  </span>
                )}
                {block.thirdSpray && (
                  <span
                    className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium text-gray-800 border border-gray-300 ${
                      block.id === blockId ? `bg-white` : `bg-gray-100`
                    }`}
                  >
                    3rd Spray
                  </span>
                )}
              </div>
            </div>
          </button>
        )
      })}
    </ul>
  )
}
