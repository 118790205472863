import { format } from 'date-fns'

// Modified from current _modelLogic.js
export default function PTGMLogic(isModelInSeason, hourlyData, state) {
  const {
    startDate,
    firstSpray,
    secondSpray,
    thirdSpray,
  } = state

  let startDateIdx = 0
  let firstSprayIdx = startDateIdx + 1
  let secondSprayIdx = firstSprayIdx + 1
  let thirdSprayIdx = secondSprayIdx + 1

  if (startDate) {
    const idxSD = hourlyData
      .slice(startDateIdx)
      .findIndex(
        (d) => parseInt(d.ms / 1000) === parseInt(startDate.getTime() / 1000),
      )
    if (idxSD !== -1) startDateIdx = idxSD
  }

  if (firstSpray) {
    if (firstSpray && typeof firstSpray.getTime === 'function') {
      const idx1 = hourlyData
        .slice(startDateIdx)
        .findIndex(
          (d) => parseInt(d.ms / 1000) === parseInt(firstSpray.getTime() / 1000),
        )
      if (idx1 !== -1) firstSprayIdx = idx1
    }
  }

  if (secondSpray) {
    const idx2 = hourlyData
      .slice(startDateIdx)
      .findIndex(
        (d) => parseInt(d.ms / 1000) === parseInt(secondSpray.getTime() / 1000),
      )
    if (idx2 !== -1) secondSprayIdx = idx2
  }

  if (thirdSpray) {
    const idx3 = hourlyData
      .slice(startDateIdx)
      .findIndex(
        (d) => parseInt(d.ms / 1000) === parseInt(thirdSpray.getTime() / 1000),
      )
    if (idx3 !== -1) thirdSprayIdx = idx3
  }

  let mData = null
  if (isModelInSeason) {
    let gdh = 0 // Cumulative Growing Degree Hours for initial growth
    let firstSprayGdh = 0
    let secondSprayGdh = 0
    let thirdSprayGdh = 0

    mData = hourlyData.slice(startDateIdx).map((d, i) => {
      if (d.temp === 'M') {
        return {
          ...d,
          temp: 'N/A',
          gdh: 'N/A',
          percentStyletPenetration: 'N/A',
          upperBound: 'N/A',
          lowerBound: 'N/A',
        }
      } else {
        // Calculate Growing Degree Hours
        const baseTemp = 39.2
        const temp = Math.round(d.temp)
        const hourlyGdh = temp > baseTemp ? temp - baseTemp : 0

        // Default calculation starting from the start date
        gdh += hourlyGdh
        let activeGdh = gdh

        // Reset calculations at spray dates
        if (firstSpray && i >= firstSprayIdx) {
          if (i === firstSprayIdx) firstSprayGdh = 0 // Reset at first spray
          firstSprayGdh += hourlyGdh
          activeGdh = firstSprayGdh
        }

        if (secondSpray && i >= secondSprayIdx) {
          if (i === secondSprayIdx) secondSprayGdh = 0 // Reset at second spray
          secondSprayGdh += hourlyGdh
          activeGdh = secondSprayGdh
        }

        if (thirdSpray && i >= thirdSprayIdx) {
          if (i === thirdSprayIdx) thirdSprayGdh = 0 // Reset at third spray
          thirdSprayGdh += hourlyGdh
          activeGdh = thirdSprayGdh
        }

        // Calculate Percent Stylet Penetration with the linear model for now (allow multiple models later here)
        const percentStyletPenetration = 100 * (0.11 + (0.0011 * activeGdh));

        // Calculate error bounds with the linear model as well (allow multiple models later here)
        const upperBound = percentStyletPenetration + 17;
        const lowerBound = percentStyletPenetration - 45;

        return {
          ...d,
          growingDegreeHours: Math.round(activeGdh),
          percentStyletPenetration: percentStyletPenetration,
          upperBound: upperBound,
          lowerBound: Math.max(0, lowerBound),
        }
      }
    })
  }
  // Data to display in Results Table
  let tableData = null
  if (isModelInSeason) tableData = mData.slice().reverse()

  // Data in CSV format to export
  let csvData = null
  if (isModelInSeason) {
    csvData = mData.map((h) => {
      if (h.temp !== 'N/A') {
        return {
          Date: format(h.date, 'yyyy-MM-dd HH:00'),
          'Air Temp (F)': Math.round(h.temp),
          'Growing Degree Hours': h.gdh,
          'Percent Stylet Penetration': h.percentStyletPenetration,
          'Upper Bound': h.upperBound,
          'LowerBound': h.lowerBound,
        }
      }
      return {
        Date: format(h.date, 'yyyy-MM-dd HH:00'),
        'Air Temp (F)': h.temp,
        'Growing Degree Hours': 'N/A',
        'Percent Stylet Penetration': 'N/A',
        'Upper Bound': 'N/A',
        'LowerBound': 'N/A',
      }
    })
  }

  let graphData = null
  const dataLength = mData ? mData.length : 0
  const currentHour = new Date().getHours()
  const includedHours = [0, 4, 8, 12, 16, 20, currentHour]

  if (isModelInSeason) {
    if (dataLength < 1000) {
      graphData = mData.map((d) => {
        return {
          date: d.date,
          ms: d.ms,
          formattedDate: format(d.date, 'MM-dd HH:00'),
          percentStyletPenetration: d.percentStyletPenetration,
          upperBound: d.upperBound,
          lowerBound: d.lowerBound,
          temp: d.temp,
        }
      })
    } else {
      graphData = mData
        .map((d, i) => {
          const hour = d.date.getHours()
          if (
            includedHours.includes(hour) ||
            i === startDateIdx ||
            i === firstSprayIdx ||
            i === secondSprayIdx ||
            i === thirdSprayIdx
          ) {
            return {
              date: d.date,
              ms: d.ms,
              formattedDate: format(d.date, 'MM-dd HH:00'),
              percentStyletPenetration: d.percentStyletPenetration,
              upperBound: d.upperBound,
              lowerBound: d.lowerBound,
              temp: d.temp,
            }
          }
          return null
        })
        .filter(Boolean)
    }
  }
  return {
    mData,
    tableData,
    csvData,
    graphData,
  }
}
