import { Link } from "gatsby";
import React from "react";
import HashLoader from "react-spinners/HashLoader";
import DashboardStationHeader from "../dashboardStationHeader";
import Footer from "../footer";
import Header from "../header";
import EndDate from "./endDate";
import Map from "./map";
import Modal from "./modal";
import Toggle from "./toggle";
import Seo from "../seo";
import StationsDropdownV2 from "../stationsDropdownV2";
import Subheader from "../subHeader";
import Transition from "../transition";
import NewaLogo from "../../svg/newa-logo.svg";
import BlockList from "./blockListV2";

export default function ModelLayoutNew({
  dateOfInterest,
  isAuthenticated,
  stationList,
  geoJSON,
  station,
  setStation,
  isModelInSeason,
  data,
  isLoading,
  modelData,
  visibleElements,
  titleSize,
  moreInfo,
  setIsMoreInfo,
  showResultsTable = null,
  setShowResultsTable,
  showResultsGraph = null,
  setShowResultsGraph,
  showEnvVarGraph = null,
  setShowEnvVarGraph,
  references,
  acknowledgments,
  isMoreInfo,
  createBlock,
  blockId,
  blocks,
  setBlock,
  modelLogic,
  dispatch,
  lastHourWithData,
  tutorialLink,
  children,
  h1Text = { h1Text },
}) {
  const { title, description } = modelData;
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const [isAcknowledgments, setIsAcknowledgments] = React.useState(false);
  const [isReferences, setIsReferences] = React.useState(false);
  const [showMap, setShowMap] = React.useState(false);

  const toggleElements = visibleElements.map((el) => {
    const { title } = el;
    let showItem = null;
    let setShowItem = null;

    if (title === "Growth Table") {
      showItem = showResultsTable;
      setShowItem = setShowResultsTable;
    }
    if (title === "Growth Graph") {
      showItem = showResultsGraph;
      setShowItem = setShowResultsGraph;
    }
    if (title === "Hourly Temperature Graph") {
      showItem = showEnvVarGraph;
      setShowItem = setShowEnvVarGraph;
    }

    return (
      <span key={title} className={isModelInSeason ? `` : `hidden`}>
        <Toggle
          text={title}
          showItem={showItem}
          setShowItem={setShowItem}
        ></Toggle>
      </span>
    );
  });

  return (
    <>
      <Seo title={title} />
      <div className="flex h-screen overflow-hidden bg-gray-50">
        {/* <!-- Off-canvas menu for mobile --> */}
        <div className="md:hidden">
          <div className={`fixed inset-0 flex ${sidebarOpen ? `z-40` : `z-0`}`}>
            <Transition
              show={sidebarOpen}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0">
                <div className="absolute inset-0 bg-gray-600 opacity-75"></div>
              </div>
            </Transition>

            <Transition
              show={sidebarOpen}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex flex-col flex-1 w-full max-w-xs bg-white">
                <div className="absolute top-0 right-0 p-1 -mr-14">
                  <button
                    onClick={() => setSidebarOpen(false)}
                    className="flex items-center justify-center w-12 h-12 rounded-full focus:outline-none focus:bg-gray-600"
                    aria-label="Close sidebar"
                  >
                    <svg
                      className="w-6 h-6 text-white"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                  <div className="flex items-center flex-shrink-0 px-4">
                    <div className="flex flex-col items-center w-full mb-8 md:hidden">
                      <div className="pb-2">
                        <Link
                          to={isAuthenticated ? "/user" : "/"}
                          className="text-sm font-semibold leading-6 tracking-wider text-gray-600 no-underline md:text-lg hover:underline"
                        >
                          {isAuthenticated ? "Dashboard" : "Home"}
                        </Link>
                      </div>
                      <div className="py-2">
                        <Link
                          to="/weather-tools"
                          className="text-sm font-semibold leading-6 tracking-wider text-gray-600 no-underline md:text-lg hover:underline"
                        >
                          Weather Tools
                        </Link>
                      </div>
                      <div className="py-2">
                        <Link
                          to="/crop-and-pest-management"
                          className="text-sm font-semibold leading-6 tracking-wider text-gray-600 no-underline md:text-lg hover:underline"
                        >
                          Crop & IPM Tools
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="px-2 mt-3">
                    <Link
                      to={isAuthenticated ? "/user" : "/"}
                      className="hidden md:inline-block md:w-28 lg:w-40"
                    >
                      <NewaLogo></NewaLogo>
                    </Link>
                    {tutorialLink && (
                      <div className="flex items-center justify-center mt-6 uppercase">
                        <a
                          className="inline-flex items-center px-3 py-2 text-sm font-medium leading-4 text-white no-underline border border-transparent rounded-md shadow-sm bg-secondary-600 hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500 hover:text-white"
                          href={tutorialLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          watch tutorial
                          <svg
                            className="ml-2 -mr-0.5 h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                            <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                          </svg>
                        </a>
                      </div>
                    )}
                    <div className="relative z-10">
                      <StationsDropdownV2
                        options={stationList}
                        value={station}
                        setValue={setStation}
                        isMobile={true}
                        setSidebarOpen={setSidebarOpen}
                      ></StationsDropdownV2>
                    </div>

                    <hr className="w-3/4 mx-auto mt-16"></hr>
                    <div className="w-full mt-6">
                      <span className="block mb-1 text-sm font-extrabold leading-5 text-secondary-800">
                        End Date
                      </span>
                      <EndDate
                        setSidebarOpen={setSidebarOpen}
                        isMobile={true}
                        isProfile={true}
                        lastHourWithData={lastHourWithData}
                      ></EndDate>
                    </div>

                    {dateOfInterest.isCurrentYear && (
                      <div className="flex items-center justify-center mt-6 ">
                        <button
                          type="button"
                          aria-label="Add new block"
                          onClick={createBlock}
                          className="inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-secondary-700 hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500"
                        >
                          <svg
                            className="w-6 h-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                            />
                          </svg>
                          Creatine Block
                        </button>
                      </div>
                    )}

                    <hr className="w-3/4 mx-auto mt-16"></hr>
                    <div className="mt-6">
                      <span className="block mb-1 text-sm font-extrabold leading-5 text-secondary-800">
                        Blocks
                      </span>

                      {isModelInSeason ? (
                        <>
                          {blocks.length !== 0 && (
                            <BlockList
                              blocks={blocks}
                              isModelInSeason={isModelInSeason}
                              hourlyData={data.hourlyData}
                              modelLogic={modelLogic}
                              setBlock={setBlock}
                              blockId={blockId}
                              isLoading={isLoading}
                            ></BlockList>
                          )}
                        </>
                      ) : (
                        <div className="mt-2 text-xs font-semibold leading-relaxed text-gray-500">
                          Select End Date between January 1st and June 30th.
                        </div>
                      )}
                    </div>
                    <hr className="w-3/4 mx-auto mt-16"></hr>
                    <div className="mt-6">
                      <span className="block mb-1 text-sm font-extrabold leading-5 text-secondary-800">
                        Show/Hide
                      </span>
                      <div className="pointer-events-none opacity-30 sm:opacity-100 sm:pointer-events-auto">
                        <Toggle
                          text="Station Selection Map"
                          showItem={showMap}
                          setShowItem={setShowMap}
                        ></Toggle>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
            <div className="flex-shrink-0 w-14">
              {/* <!-- Force sidebar to shrink to fit close icon --> */}
            </div>
          </div>
        </div>

        {/* <!-- Static sidebar for desktop --> */}
        <div className="relative hidden md:flex md:flex-shrink-0">
          <div className="flex flex-col bg-white border-r border-gray-200 w-72">
            <div className="flex flex-col flex-1 h-0 pt-5 pb-4 overflow-y-auto">
              {/* <!-- Sidebar component, swap this element with another sidebar if you like --> */}
              <div className="flex-1 px-2 bg-white">
                <div className="flex flex-col justify-center items-center">
                  <Link
                    to={isAuthenticated ? "/user" : "/"}
                    className="hidden md:inline-block md:w-28 lg:w-40"
                  >
                    <NewaLogo></NewaLogo>
                  </Link>
                  <div>
                    {tutorialLink && (
                      <div className="flex items-center justify-center mt-6 uppercase">
                        <a
                          className="inline-flex items-center px-3 py-2 text-sm font-medium leading-4 text-white no-underline border border-transparent rounded-md shadow-sm bg-secondary-600 hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500 hover:text-white"
                          href={tutorialLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          watch tutorial
                          <svg
                            className="ml-2 -mr-0.5 h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                            <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                          </svg>
                        </a>
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-16">
                  <span className="block mb-1 text-sm font-extrabold leading-5 text-secondary-800">
                    {isAuthenticated ? "Favorite Stations" : "All Stations"}
                  </span>
                  <StationsDropdownV2
                    options={stationList}
                    value={station}
                    setValue={setStation}
                  ></StationsDropdownV2>
                </div>

                <hr className="w-3/4 mx-auto mt-16"></hr>
                <div className="mt-6">
                  <span className="block mb-1 text-sm font-extrabold leading-5 text-secondary-800">
                    End Date
                  </span>
                  <EndDate
                    setSidebarOpen={setSidebarOpen}
                    isMobile={false}
                    indexPageDispatch={dispatch}
                    lastHourWithData={lastHourWithData}
                  ></EndDate>
                </div>

                <hr className="w-3/4 mx-auto mt-16"></hr>
                {(dateOfInterest.isCurrentYear || true) && (
                  <div className="flex items-center justify-center mt-6 ">
                    <button
                      type="button"
                      aria-label="Add new block"
                      onClick={createBlock}
                      className="inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-secondary-700 hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500"
                    >
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                        />
                      </svg>
                      Create V2 Block
                    </button>
                  </div>
                )}

                <div className="mt-6">
                  <span className="block mb-1 text-sm font-extrabold leading-5 text-secondary-800">
                    Blocks
                  </span>

                  {isModelInSeason ? (
                    <>
                      {blocks.length !== 0 && (
                        <BlockList
                          blocks={blocks}
                          isModelInSeason={isModelInSeason}
                          hourlyData={data.hourlyData}
                          modelLogic={modelLogic}
                          setBlock={setBlock}
                          blockId={blockId}
                          isLoading={isLoading}
                        ></BlockList>
                      )}
                    </>
                  ) : (
                    <div className="mt-2 text-xs font-semibold leading-relaxed text-gray-500">
                      Select End Date between January 1st and June 30th.
                    </div>
                  )}
                </div>

                <hr className="w-3/4 mx-auto mt-16"></hr>
                <div className="mt-6">
                  <span className="block mb-1 text-sm font-extrabold leading-5 text-secondary-800">
                    Show/Hide
                  </span>
                  <div className="pointer-events-none opacity-30 sm:opacity-100 sm:pointer-events-auto">
                    <Toggle
                      text="Station Selection Map"
                      showItem={showMap}
                      setShowItem={setShowMap}
                    ></Toggle>
                  </div>
                  {station && toggleElements}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Right Side */}
        <div className={`flex flex-col w-0 flex-1 overflow-hidden z-0`}>
          <div
            className={`md:hidden flex items-center justify-between pl-1 pt-1 sm:pl-3 sm:pt-3 pr-4 `}
          >
            <button
              onClick={() => setSidebarOpen(true)}
              className={`-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150`}
              aria-label="Open sidebar"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>

          <main
            className="relative flex-1 h-screen overflow-y-auto z-1 focus:outline-none"
            // tabIndex="0"
          >
            <div className="pb-6">
              {/* Header  - Model Title */}
              <div className="mx-auto max-w-7xl">
                <Header
                  color="bg-white"
                  isModel={true}
                  title={title}
                  titleSize={titleSize}
                ></Header>
              </div>

              {/* Subheader */}
              <div>
                <Subheader h1Text={h1Text}></Subheader>
              </div>

              {/* Model Description */}
              <div className="px-4 mx-auto max-w-7xl xl:px-6 lg:px-8">
                <p dangerouslySetInnerHTML={{ __html: description }} />
              </div>

              {/* Station Header */}
              <div className="px-4 mx-auto mt-6 max-w-7xl sm:px-6 md:px-8">
                <div>
                  {/* Station Header */}
                  {station && (
                    <>
                      <div className="mb-16 sm:mb-18 md:mb-20">
                        <DashboardStationHeader
                          station={station}
                          data={data}
                          isLoading={isLoading}
                        ></DashboardStationHeader>
                      </div>

                      {isLoading && (
                        <div className="flex items-center justify-center my-16 xl:my-18 lg:my-20">
                          <HashLoader
                            size={48}
                            color={"#1987C2"}
                            loading={isLoading}
                          />
                        </div>
                      )}
                    </>
                  )}

                  {/* Station Selection Map */}
                  {showMap && (
                    <div
                      className="mb-16 sm:mb-18 md:mb-20"
                      aria-label="NEWA stations map"
                    >
                      <h2 className="mb-3 font-semibold text-gray-600 sm:mb-5 md:mb-6 md:text-2xl">
                        Station Selection Map
                      </h2>
                      <div
                        className="overflow-hidden rounded-lg shadow-lg"
                        aria-hidden="true"
                      >
                        <Map
                          station={station}
                          setStation={setStation}
                          geoJSON={geoJSON}
                        ></Map>
                      </div>
                    </div>
                  )}

                  {children}

                  <div className="flex items-center justify-center mb-16 sm:mb-18 md:mb-20">
                    <div className="flex flex-col justify-between w-full max-w-md my-1 sm:my-2 sm:flex-row">
                      <button
                        type="button"
                        onClick={() => setIsMoreInfo(true)}
                        aria-haspopup="true"
                        className="py-4 text-center text-gray-500 border-b border-gray-500 sm:py-0 md:text-xl hover:text-gray-700 hover:border-gray-700"
                      >
                        More Info
                      </button>
                      <button
                        type="button"
                        onClick={() => setIsAcknowledgments(true)}
                        aria-haspopup="true"
                        className="py-4 text-center text-gray-500 border-b border-gray-500 sm:py-0 md:text-xl hover:text-gray-700 hover:border-gray-700"
                      >
                        Acknowledgments
                      </button>
                      <button
                        type="button"
                        onClick={() => setIsReferences(true)}
                        aria-haspopup="true"
                        className="py-4 text-center text-gray-500 border-b border-gray-500 sm:py-0 md:text-xl hover:text-gray-700 hover:border-gray-700"
                      >
                        References
                      </button>
                    </div>
                  </div>
                  {isMoreInfo && (
                    <Modal
                      title="More Info"
                      isModalOpen={isMoreInfo}
                      setModal={setIsMoreInfo}
                    >
                      {moreInfo}
                    </Modal>
                  )}
                  {isAcknowledgments && (
                    <Modal
                      title="Acknowledgments"
                      isModalOpen={isAcknowledgments}
                      setModal={setIsAcknowledgments}
                    >
                      {acknowledgments()}
                    </Modal>
                  )}
                  {isReferences && (
                    <Modal
                      title="References"
                      isModalOpen={isReferences}
                      setModal={setIsReferences}
                    >
                      {references()}
                    </Modal>
                  )}

                  {/* Footer */}
                  <Footer
                    color="bg-secondary-800"
                    tutorialLink={tutorialLink}
                  ></Footer>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
