import domtoimage from 'dom-to-image'
import { saveAs } from 'file-saver'
import React from 'react'
import {
  ComposedChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Area,
  CartesianGrid,
  ReferenceLine,
  ReferenceDot,
} from 'recharts'
import { format } from "date-fns";

const CustomXLabel = (props) => {
  const { x, y, payload } = props
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={20}
        fontSize={11}
        textAnchor="middle"
        fill="#666"
        transform="rotate(-13)"
      >
        {payload.value}
      </text>
    </g>
  )
}

const CustomYLabel = (props) => {
  const { x, y, payload, unit } = props
  return (
    <g>
      <text
        x={x}
        y={y}
        dy={5}
        dx={-15}
        fontSize={11}
        textAnchor="middle"
        fill="#666"
      >
        {payload.value}
        {unit}
      </text>
    </g>
  )
}

const CustomizedReferenceLineLabel = (props) => {
  const { date, viewBox } = props
  return (
    <g>
      <text
        x={viewBox.x}
        y={viewBox.y}
        dy={-5}
        fontSize={11}
        textAnchor="middle"
      >
        {date}
      </text>
    </g>
  )
}

const CustomTooltip = ({ payload }) => {
  if (!payload || payload.length === 0) return null
  return payload.map((entry, i) => {
    if (entry.dataKey !== 'percentStyletPenetration') return;

    return (
      <div
        key={i}
        className="flex flex-col px-4 py-3 rounded bg-gray-50 opacity-90"
      >
        <div>
          <span className="mr-1 text-xs font-bold">Date:</span>
          <span className="text-xs">{entry.payload.formattedDate}</span>
        </div>
        <div className="mt-2">
          <span className="mr-1 text-xs font-bold">Percent Stylet Penetration:</span>
          <span className="text-xs">
            {entry.payload.percentStyletPenetration === 'N/A'
              ? 'N/A'
              : Math.round(entry.payload.percentStyletPenetration)}
          </span>
        </div>
        <div className="mt-1">
          <span className="mr-1 text-xs font-bold">Upper Bound:</span>
          <span className="text-xs">
            {entry.payload.upperBound === 'N/A'
              ? 'N/A'
              : Math.round(entry.payload.upperBound)}
          </span>
        </div>
        <div className="mt-1">
          <span className="mr-1 text-xs font-bold">Lower Bound:</span>
          <span className="text-xs">
            {entry.payload.lowerBound === 'N/A'
              ? 'N/A'
              : Math.round(entry.payload.lowerBound)}
          </span>
        </div>
      </div>
    )
  })
}

export default function GrowthGraph({ modelData, data, currentHourObject, state }) {
  const { resultsGraph } = modelData.elements
  const { firstSpray, secondSpray, thirdSpray } = state || {}

  for (let i = 0; i < data.length; i++) {
    if (data[i].percentStyletPenetration > 250 ) {
      data[i].percentStyletPenetration = 250
    }
    if (data[i].upperBound > 250) {
      data[i].upperBound = 250
    }
    if (data[i].lowerBound > 250) {
      data[i].lowerBound = 250
    }
  }

  let currenHourGraphObject = null
  if (currentHourObject)
    currenHourGraphObject = data.find((d) => d.ms === currentHourObject.ms)

  // Add formatted date to spray dates
  if (firstSpray) {
    firstSpray.formattedDate = format(firstSpray, 'MM-dd HH:00');
  }
  if (secondSpray) {
    secondSpray.formattedDate = format(secondSpray, 'MM-dd HH:00');
  }
  if (thirdSpray) {
    thirdSpray.formattedDate = format(thirdSpray, 'MM-dd HH:00');
  }

  function downloadPNG() {
    domtoimage
      .toBlob(document.getElementById(`${resultsGraph.title}-growth-graph`))
      .then((blob) => saveAs(blob, `${modelData.title} Chart.png`))
  }

  if (!data) {
    return null;
  }

  return (
    <div className="w-full" aria-hidden="true">
      <div className="flex items-center justify-between mb-6">
        <h2 className="inline-block text-lg font-semibold text-gray-600 sm:text-xl md:text-2xl">
          {resultsGraph.title}
        </h2>

        <div className="flex items-center justify-center rounded-md">
          <button
            type="button"
            aria-label="download graph in png format"
            onClick={downloadPNG}
            className="inline-flex items-center p-2 text-sm font-bold leading-4 text-white transition duration-150 ease-in-out border border-transparent rounded-md sm:px-3 sm:py-2 bg-secondary-800 hover:bg-secondary-700 focus:outline-none focus:border-secondary-900 focus:shadow-outline-secondary active:bg-secondary-900"
          >
            <svg
              className="-ml-0.5 mr-2 h-4 w-4 text-white"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path>
            </svg>
            <span className="hidden mr-1 sm:inline-block">Download </span>{' '}
            <span className="text-white">PNG</span>
          </button>
        </div>
      </div>

      <ResponsiveContainer
        width="100%"
        height={450}
        id={`${resultsGraph.title}-growth-graph`}
      >
        <ComposedChart
          data={data}
          margin={{ top: 20, right: 35, left: 20, bottom: 20 }}
          className="bg-white border-b border-gray-200 shadow sm:rounded-lg"
        >
          <XAxis
            dataKey="formattedDate"
            interval={'preserveStartEnd'}
            axisLine={true}
            tick={<CustomXLabel />}
          ></XAxis>
          <YAxis
            id="yAxis"
            dataKey="percentStyletPenetration"
            tick={<CustomYLabel unit={'%'} />}
          />
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <Tooltip content={<CustomTooltip />} />
          <Legend verticalAlign="top" height={60} />

          {/* Lower bound line - dashed pattern */}
          <Area
            type="monotone"
            dataKey="lowerBound"
            stroke="#6B7280"
            strokeDasharray="5 5"
            fill="none"
            dot={false}
            name="Lower Bound"
          />

          {/* Main PSP line */}
          <Area
            type="monotone"
            dataKey="percentStyletPenetration"
            stroke="#1D99E1"
            fill="#86BFDF"
            dot={false}
            name="Percent Stylet Penetration"
          />

          {/* Upper bound line - dashed pattern */}
          <Area
            type="monotone"
            dataKey="upperBound"
            stroke="#FBC280"
            strokeDasharray="5 1 5"
            fill="none"
            dot={false}
            name="Upper Bound"
          />

          {/* Reference line for 100% */}
          <ReferenceLine
            y={100}
            strokeDasharray="0 3 0"
            type="monotone"
            stroke="#267E1E99"
            strokeWidth={1.4}
            label={{ value: '100%', position: 'right', fontSize: 11, fill: '#666' }}
          />

          {/* Reference lines for spray dates */}
          {firstSpray && (
            <ReferenceLine
              x={firstSpray.formattedDate}
              stroke="#F59E0B"
              strokeWidth={1.1}
              label={{ value: 'Spray 1', position: 'top', fontSize: 11, fill: '#666' }}
            />
          )}

          {secondSpray && (
            <ReferenceLine
              x={secondSpray.formattedDate}
              stroke="#F59E0B"
              strokeWidth={1.1}
              label={{ value: 'Spray 2', position: 'top', fontSize: 11, fill: '#666' }}
            />
          )}

          {thirdSpray && (
            <ReferenceLine
              x={thirdSpray.formattedDate}
              stroke="#F59E0B"
              strokeWidth={1.1}
              label={{ value: 'Spray 3', position: 'top', fontSize: 11, fill: '#666' }}
            />
          )}

          {currenHourGraphObject && (
            <ReferenceLine
              x={currenHourGraphObject.formattedDate}
              stroke="#4a5568"
              strokeWidth={1.1}
              label={
                <CustomizedReferenceLineLabel
                  date={currenHourGraphObject.formattedDate}
                />
              }
            />
          )}
          {currenHourGraphObject && (
            <ReferenceDot
              x={currenHourGraphObject.formattedDate}
              y={currenHourGraphObject.percentStyletPenetration}
              r={4}
              fill="#4a5568"
              stroke="none"
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}