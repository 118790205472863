import React from "react";
import Select from "react-select";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../../tailwind.config";
import Modal from "../../components/models/modal";
import ModelLayoutNew from "../../components/models/modelLayoutNew_refactor";
import SprayDateHr from "../../components/models/sprayDate";
import GlobalStateContext from "../../context/globalStateContext";
import { useAuth0 } from "../../context/react-auth0-spa";
import { modelInSeason, sdateEdate, setDate } from "../../utils/helpers";
import useFetchStationData from "../../utils/hooks/useFetchStationData";
import useStations from "../../utils/hooks/useStations";
import modelData from "./pollen-tube-growth-model.json";
import Acknowledgments from "./_acknowledgements";
import Block from "./_block";
import GrowthGraph from "./_growthGraph";
import HourlyTemperatureGraph from "./_hrTempGraph";
import modelLogic from "./_modelLogic";
import MoreInfo from "./_moreInfo";
import References from "./_references";
import ResultsTable from "./_resultsTable";

const { theme } = resolveConfig(tailwindConfig);
const { colors, borderRadius } = theme;

const pollenTubeModels = [
  { value: "linear-univariate", label: "Linear Model | Univariate" },
  // { value: "logistic-univariate", label: "Logistic Model | Univariate" },
  // { value: "linear-multivariate", label: "Linear Model | Multivariate" },
  // { value: "logistic-multivariate", label: "Logistic Model | Multivariate" },
];

const initialState = {
  id: null,
  stationId: "",
  year: "",
  blockName: "",
  pollenTubeModel: "",
  startDate: null,
};

function blockFormReducer(state, action) {
  switch (action.type) {
    case "initialState":
      return initialState;
    case "setBlockName":
      return {
        ...state,
        blockName: action.blockName,
      };
    case "setPollenTubeModel":
      return {
        ...state,
        pollenTubeModel: action.pollenTubeModel,
      };
    case "setStartDate":
      return {
        ...state,
        startDate: action.startDate,
      };
    case "setFirstSpray":
      return {
        ...state,
        firstSpray: action.firstSpray,
        secondSpray: action.firstSpray === null ? null : state.secondSpray,
        thirdSpray: action.firstSpray === null ? null : state.thirdSpray,
      };
    case "setSecondSpray":
      return {
        ...state,
        secondSpray: action.secondSpray,
        thirdSpray: action.secondSpray === null ? null : state.thirdSpray,
      };
    case "setThirdSpray":
      return {
        ...state,
        thirdSpray: action.thirdSpray,
      };
    case "setBlock":
      return action.block;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

function convertDatesToServer(block) {
  let p = { ...block };
  if (block.startDate !== null) p.startDate = block.startDate.toISOString();
  if (block.firstSpray != null) p.firstSpray = block.firstSpray.toISOString();
  if (block.secondSpray != null) p.secondSpray = block.secondSpray.toISOString();
  if (block.thirdSpray != null) p.thirdSpray = block.thirdSpray.toISOString();
  return p;
}

function convertDatesFromServer(block) {
  let p = { ...block };
  if (block.startDate) p.startDate = new Date(block.startDate);
  if (block.firstSpray) p.firstSpray = new Date(block.firstSpray);
  if (block.secondSpray) p.secondSpray = new Date(block.secondSpray);
  if (block.thirdSpray) p.thirdSpray = new Date(block.thirdSpray);
  return p;
}

const visibleElements = Object.entries(modelData.elements)
  .filter(([_, value]) => value.priority)
  .filter(([key, _]) => !key.includes("user"))
  .map((arr) => ({ ...arr[1], toggleName: arr[0] }))
  .sort((a, b) =>
    a.priority > b.priority ? 1 : b.priority > a.priority ? -1 : 0
  );

export default function PollenTubeGrowthModel() {
  const h1Text = modelData.title;
  const { id, seasonEnd } = modelData;
  const { isAuthenticated, user, setUser } = useAuth0();
  const { stationList, station, setStation, favoriteStations, geoJSON } =
    useStations();
  const [blocks, setBlocks] = React.useState([]);
  const { dateOfInterest: dddd } = React.useContext(GlobalStateContext);
  const dateOfInterest =
    dddd.ms > new Date(dddd.year, 5, 30, 23, 0).getTime()
      ? setDate(new Date(dddd.year, 5, 30, 23, 0))
      : dddd;

  const isModelInSeason = modelInSeason(dateOfInterest, modelData);

  const { sdate, edate } = sdateEdate(dateOfInterest, seasonEnd);
  const { isLoading, data } = useFetchStationData(station, sdate, edate);

  const [isModal, setIsModal] = React.useState(false);
  const [isMoreInfo, setIsMoreInfo] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [showResultsTable, setShowResultsTable] = React.useState(false);
  const [showResultsGraph, setShowResultsGraph] = React.useState(false);
  const [showEnvVarGraph, setShowEnvVarGraph] = React.useState(false);

  let filteredBlocks = [];
  if (station) {
    // console.log('All blocks (v2):', JSON.stringify(blocks, null, 2));
    // console.log('Current station (v2):', JSON.stringify(station, null, 2));
    console.log('Current year (v2):', dateOfInterest.year);

    filteredBlocks = blocks.filter(
      (block) => {
        const isYearMatch = block.year === dateOfInterest.year;
        const isStationMatch = block.stationId === station.id;

        console.log(`Block details (v2): ${JSON.stringify(block, null, 2)}`);
        console.log(`Year match: ${isYearMatch}, Station match: ${isStationMatch}`);

        return isYearMatch && isStationMatch;
      }
    );

    // console.log('Filtered blocks (v2):', JSON.stringify(filteredBlocks, null, 2));
  }

  const [state, dispatch] = React.useReducer(
    blockFormReducer,
    filteredBlocks.length === 0 ? initialState : filteredBlocks[0]
  );

  let hourlyData = [];
  let datesOnlyInMs = [];
  let startDateMinDate = null;
  let endDate = dateOfInterest.date;
  let endIdx = 1;
  let mData = null;
  let lastHourWithData = 0;
  if (data) {
    hourlyData = data.hourlyData.filter((d) => d.temp !== "M");
    startDateMinDate = hourlyData[0].date;
    if (dateOfInterest.isCurrentYear) {
      endIdx = hourlyData.length - 1;
    } else {
      const idx = hourlyData.findIndex((d) => d.ms === endDate.getTime());
      if (idx !== -1) endIdx = idx;
    }
    hourlyData = hourlyData.slice(0, endIdx + 1);
    lastHourWithData = hourlyData[endIdx].date.getHours();
    datesOnlyInMs = hourlyData.map((d) => d.ms); // for date pickers
  }

  React.useEffect(() => {
    if (!user) return;

    if (Object.keys(user.activeTools)) {
      const model = user.activeTools.find((model) => model.id === id);
      if (model) {
        const blocks = model.blocks.map((b) => convertDatesFromServer(b));
        if (blocks.length !== 0) {
          setBlocks(blocks);
        }
      }
    }
  }, [user, id]);

  React.useEffect(() => {
    const filteredBlocks = blocks.filter(
      (block) => {
        const isYearMatch = block.year === dddd.year;
        const isStationMatch = block.stationId === station.id;

        return isYearMatch && isStationMatch;
      }
    );
    if (filteredBlocks.length === 0) {
      dispatch({ type: "initialState" });
      setShowResultsTable(false);
      setShowResultsGraph(false);
      setShowEnvVarGraph(false);
    } else {
      dispatch({ type: "setBlock", block: filteredBlocks[0] });
      setShowResultsTable(true);
      setShowResultsGraph(true);
      setShowEnvVarGraph(true);
    }
  }, [dddd, station, blocks, lastHourWithData]);

  ////////////////////////////////////////////////////////////////////////////
  let firstSprayDatesMs = [];
  let firstSprayMinDate = null;
  let firstSprayMaxDate = null;
  let startDateIdx = 0;
  if (state.startDate) {
    const idx = hourlyData.findIndex((d) => d.ms === state.startDate.getTime());
    if (idx !== -1) {
      startDateIdx = idx;
      firstSprayDatesMs = hourlyData.slice(startDateIdx + 1).map((d) => d.ms);
      firstSprayMinDate = hourlyData[startDateIdx + 1].date;
      firstSprayMaxDate = hourlyData[hourlyData.length - 1].date;
    }
  }

  let startDatesMs = [];
  let startDateMaxDate = null;
  let secondSprayDatesMs = [];
  let secondSprayMinDate = null;
  let secondSprayMaxDate = null;
  let firstSprayIdx = startDateIdx + 1;

  if (state.firstSpray && typeof state.firstSpray === 'object' && hourlyData.length !== 0) {
    const ms = state.firstSpray.getTime().toString();
    const maskedMs = ms.slice(0, -3) + "000";
    const idx = hourlyData.findIndex((d) => d.ms === Number(maskedMs));

    if (idx !== -1) {
      firstSprayIdx = idx;
      startDatesMs = hourlyData.slice(0, firstSprayIdx).map((d) => d.ms);
      startDateMaxDate = hourlyData[firstSprayIdx].date;
      if (idx === hourlyData.length - 1) {
        secondSprayDatesMs = hourlyData.slice(firstSprayIdx).map((d) => d.ms);
        secondSprayMinDate = hourlyData[firstSprayIdx].date;
      } else {
        secondSprayDatesMs = hourlyData
          .slice(firstSprayIdx + 1)
          .map((d) => d.ms);
        secondSprayMinDate = hourlyData[firstSprayIdx + 1].date;
      }
      secondSprayMaxDate = hourlyData[hourlyData.length - 1].date;
    }
  }

  let thirdSprayDatesMs = [];
  let thirdSprayMinDate = null;
  let thirdSprayMaxDate = null;
  let secondSprayIdx = firstSprayIdx + 1;
  if (state.secondSpray && hourlyData.length !== 0) {
    const ms = state.secondSpray.getTime().toString();
    const maskedMs = ms.slice(0, -3) + "000";
    const idx = hourlyData.findIndex((d) => d.ms === Number(maskedMs));

    if (idx !== -1) {
      secondSprayIdx = idx;
      firstSprayDatesMs = hourlyData
        .slice(startDateIdx + 1, secondSprayIdx)
        .map((d) => d.ms);
      firstSprayMinDate = hourlyData[startDateIdx + 1].date;
      firstSprayMaxDate = hourlyData[secondSprayIdx - 1].date;
      if (idx === hourlyData.length - 1) {
        thirdSprayDatesMs = hourlyData.slice(secondSprayIdx).map((d) => d.ms);
        thirdSprayMinDate = hourlyData[secondSprayIdx].date;
      } else {
        thirdSprayDatesMs = hourlyData
          .slice(secondSprayIdx + 1)
          .map((d) => d.ms);
        thirdSprayMinDate = hourlyData[secondSprayIdx + 1].date;
      }
      thirdSprayMaxDate = hourlyData[hourlyData.length - 1].date;
    }
  }

  let thirdSprayIdx = secondSprayIdx + 1;
  if (state.thirdSpray && hourlyData.length !== 0) {
    const ms = state.thirdSpray.getTime().toString();
    const maskedMs = ms.slice(0, -3) + "000";
    const idx = hourlyData.findIndex((d) => d.ms === Number(maskedMs));

    if (idx !== -1) {
      thirdSprayIdx = idx;
      secondSprayDatesMs = hourlyData
        .slice(firstSprayIdx + 1, thirdSprayIdx)
        .map((d) => d.ms);

      if (idx === hourlyData.length - 1) {
        secondSprayMinDate = hourlyData[secondSprayIdx].date;
      } else {
        secondSprayMinDate = hourlyData[secondSprayIdx + 1].date;
      }
      secondSprayMaxDate = hourlyData[thirdSprayIdx - 1].date;
    }
  }

  ////////////////////////////////////////////////////////////////////////////
  if (
    !isLoading &&
    hourlyData.length !== 0 &&
    state.startDate !== null &&
    !isModal
  ) {
    let observedAndForecastHourlyData = [];
    if (Date.now() > new Date(dddd.year, 5, 30, 23, 0).getTime()) {
      const idx = data.hourlyData.findIndex((d) => d.ms === dateOfInterest.ms);
      observedAndForecastHourlyData = data.hourlyData.slice(0, idx + 1);
    } else {
      observedAndForecastHourlyData = [
        ...data.hourlyData,
        ...data.hourlyFcstData,
      ];
    }

    console.log({ isModelInSeason, observedAndForecastHourlyData, state })
    mData = modelLogic(isModelInSeason, observedAndForecastHourlyData, state);
  }

  const isVisible =
    station &&
    !isLoading &&
    mData !== null &&
    state.startDate !== null;

  function createBlock() {
    dispatch({ type: "initialState" });
    setIsModal(true);
    setIsEdit(false);
  }

  function addBlock() {
    // console.log("addBlock")
    if (state.blockName.length !== 0 && state.pollenTubeModel) {
      let stateCopy = { ...state };
      stateCopy.id = Date.now();
      stateCopy.stationId = station.id;
      stateCopy.year = dateOfInterest.year;
      stateCopy.blockName =
        stateCopy.blockName.charAt(0).toUpperCase() +
        stateCopy.blockName.slice(1);
      setBlocks([stateCopy, ...blocks]);
      dispatch({ type: "setBlock", block: stateCopy });

      if (user) {
        const model = user.activeTools.find((model) => model.id === id);
        const activeToolsUpdated = user.activeTools.filter(
          (t) => t.name !== model.name
        );
        const stateCopyDatesConverted = convertDatesToServer(stateCopy);
        if (model) {
          model["blocks"] = [stateCopyDatesConverted, ...model.blocks];
          setUser({
            ...user,
            activeTools: [...activeToolsUpdated, model],
          });
        }
      }
      setIsModal(false);
      setIsEdit(false);
    }
  }

  function updateBlock(id) {
    // console.log("updateBlock", id)
    const updatedBlocks = blocks.filter((b) => b.id !== id);
    setBlocks([state, ...updatedBlocks]);
    if (user) {
      let model = user.activeTools.find((d) => d.id === modelData.id);
      const activeToolsUpdated = user.activeTools.filter(
        (t) => t.name !== model.name
      );
      if (model) {
        model["blocks"] = [state, ...updatedBlocks].map((b) =>
          convertDatesToServer(b)
        );
        setUser({
          ...user,
          activeTools: [...activeToolsUpdated, model],
        });
      }
    }
    setIsModal(false);
    setIsEdit(false);
  }

  function setBlock(id) {
    const block = blocks.find((block) => block.id === id);
    if (block) {
      dispatch({ type: "setBlock", block });
    }
  }

  function editBlock(id) {
    // console.log("Edit Block", id)
    setIsEdit(true);
    const block = blocks.find((b) => b.id === id);
    if (block) {
      dispatch({ type: "setBlock", block });
      setIsModal(true);
    }
  }

  function deleteBlock(id) {
    // console.log("deleteBlock", id)
    const length = blocks.length;
    let bb = [];
    if (length === 1) {
      setBlocks(bb);
    } else {
      bb = blocks.filter((b) => b.id !== id);
      setBlocks(bb);
    }
    dispatch({ type: "initialState" });
    if (user) {
      let model = user.activeTools.find((t) => t.id === modelData.id);
      const activeToolsUpdated = user.activeTools.filter(
        (t) => t.name !== model.name
      );
      if (model) {
        if (length === 1) {
          model["blocks"] = [];
        } else {
          model["blocks"] = bb.map((b) => convertDatesToServer(b));
        }
        setUser({
          ...user,
          activeTools: [...activeToolsUpdated, model],
        });
      }
    }
  }

  function handleSuccess() {
    if (isEdit) {
      updateBlock(state.id);
    } else {
      addBlock();
    }
  }

  function handleCancel() {
    if (blocks.length === 0) {
      dispatch({ type: "initialState" });
    } else {
      const block = blocks[0];
      dispatch({ type: "setBlock", block });
    }
    setIsModal(false);
  }

  let currentHourObject = null;
  let percentStyletPenetration = 0;
  if (mData && mData.mData) {
    currentHourObject = mData.mData.find((d) => d.ms === dateOfInterest.ms);
    if (currentHourObject) {
      percentStyletPenetration = currentHourObject.percentStyletPenetration;
    }
  }

  return (
    <ModelLayoutNew
      dateOfInterest={dateOfInterest}
      isAuthenticated={isAuthenticated}
      stationList={user ? favoriteStations : stationList}
      station={station}
      setStation={setStation}
      allStations={stationList}
      geoJSON={geoJSON}
      data={{ hourlyData: hourlyData }}
      isModelInSeason={isModelInSeason}
      isLoading={isLoading}
      modelData={modelData}
      visibleElements={visibleElements}
      titleSize="text-xl lg:text-2xl"
      moreInfo={<MoreInfo moreInfo={modelData.moreInfo}/>}
      setIsMoreInfo={setIsMoreInfo}
      references={References}
      acknowledgments={Acknowledgments}
      isMoreInfo={isMoreInfo}
      createBlock={createBlock}
      blockId={state.id}
      blocks={filteredBlocks}
      setBlock={setBlock}
      modelLogic={modelLogic}
      showResultsTable={showResultsTable}
      setShowResultsTable={setShowResultsTable}
      showResultsGraph={showResultsGraph}
      setShowResultsGraph={setShowResultsGraph}
      showEnvVarGraph={showEnvVarGraph}
      setShowEnvVarGraph={setShowEnvVarGraph}
      dispatch={dispatch}
      lastHourWithData={lastHourWithData}
      tutorialLink={modelData.tutorialLink}
      h1Text={h1Text}
    >
      {isModelInSeason && state.id && !isLoading && (
        <div className="mb-16 sm:mb-18 md:mb-20">
          <Block
            block={state}
            editBlock={editBlock}
            deleteBlock={deleteBlock}
            dateOfInterest={dateOfInterest}
            percentStyletPenetration={percentStyletPenetration}
          ></Block>
        </div>
      )}

      {/* Growth Graph */}
      {isModelInSeason && isVisible && showResultsGraph && (
        <div className="mb-16 sm:mb-18 md:mb-20">
          <GrowthGraph
            modelData={modelData}
            data={mData.graphData}
            currentHourObject={currentHourObject}
            state={state}
          ></GrowthGraph>
        </div>
      )}

      {/* Results Table */}
      {isModelInSeason && isVisible && showResultsTable && (
        <div className="mb-16 sm:mb-18 md:mb-20">
          <ResultsTable
            station={station}
            modelData={modelData}
            tableData={mData.tableData}
            csvData={mData.csvData}
          ></ResultsTable>
        </div>
      )}

      {/* Hourly Temperatures Graph */}
      {isModelInSeason && isVisible && showEnvVarGraph && (
        <div className="mb-16 sm:mb-18 md:mb-20">
          <HourlyTemperatureGraph
            modelData={modelData}
            data={mData.graphData}
            currentHourObject={currentHourObject}
          ></HourlyTemperatureGraph>
        </div>
      )}

      {isModal && (
        <Modal
          title={`${isEdit ? "Edit" : "Create"} Block`}
          isModalOpen={isModal}
          setModal={setIsModal}
        >
          <div className="">
            <div className="mt-8">
              <label
                htmlFor="block_name"
                className="block text-sm font-medium text-left text-gray-700"
              >
                Block name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  minLength={3}
                  name="block_name"
                  placeholder="Block Name"
                  id="block_name"
                  value={state.blockName}
                  onChange={(e) =>
                    dispatch({
                      type: "setBlockName",
                      blockName: e.target.value,
                    })
                  }
                  className="block w-full bg-white border-gray-300 rounded-md shadow-sm focus:ring-secondary-500 focus:border-secondary-500 sm:text-sm"
                />
              </div>
            </div>

            {/* Pollen Tube Growth Model */}
            <div className="mt-8">
              <label
                htmlFor="pollen_tube_model"
                className="block text-sm font-medium text-left text-gray-700"
              >
                Pollen Tube Growth Model
              </label>
              <div className="mt-1">
                <Select
                  id="pollen_tube_model"
                  aria-label="pollen tube model"
                  value={state.pollenTubeModel}
                  onChange={(model) =>
                    dispatch({
                      type: "setPollenTubeModel",
                      pollenTubeModel: model,
                    })
                  }
                  placeholder={"Select Pollen Tube Growth Model"}
                  isSearchable={false}
                  options={pollenTubeModels}
                  getOptionValue={(option) => option["value"]}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: borderRadius.md,
                    colors: {
                      ...theme.colors,
                      primary25: colors.secondary["200"],
                      primary50: colors.secondary["100"],
                      primary: colors.secondary["500"],
                      neutral20: colors.gray["300"], // border
                    },
                  })}
                ></Select>
              </div>

              {/* Start Date */}
              <div className="mt-8">
                <label
                  htmlFor="start_date"
                  className="block text-sm font-medium text-left text-gray-700"
                >
                  Start Date
                </label>
                <div className="">
                  <SprayDateHr
                    id="startDate"
                    dbKey="startDate"
                    minDate={startDateMinDate}
                    maxDate={state.firstSpray ? startDateMaxDate : endDate}
                    datesOnlyInMs={
                      state.firstSpray ? startDatesMs : datesOnlyInMs
                    }
                    spray={state.startDate}
                    setUserSpray={(startDate) =>
                      dispatch({
                        type: "setStartDate",
                        startDate,
                      })
                    }
                    placeholderText="Select Start Date"
                    modelName={modelData.title}
                    tooltip={`Clear Start Date`}
                  ></SprayDateHr>
                </div>
              </div>

              {/* First Spray Date */}
              <div className="mt-8">
                <label
                  htmlFor="first_spray"
                  className="block text-sm font-medium text-left text-gray-700"
                >
                  First Spray
                </label>
                <div className="w-full">
                  <SprayDateHr
                    id="firstSpray"
                    dbKey="firstSpray"
                    minDate={firstSprayMinDate}
                    maxDate={firstSprayMaxDate}
                    datesOnlyInMs={firstSprayDatesMs}
                    selectable={state.startDate}
                    spray={state.firstSpray}
                    setUserSpray={(firstSpray) =>
                      dispatch({
                        type: "setFirstSpray",
                        firstSpray,
                      })
                    }
                    placeholderText="Select 1st Spray"
                    modelName={modelData.title}
                    tooltip={`Clear First Spray`}
                  ></SprayDateHr>
                </div>
              </div>

              {/* Second Spray Date */}
              <div className="mt-8">
                <label
                  htmlFor="first_spray"
                  className="block text-sm font-medium text-left text-gray-700"
                >
                  Second Spray
                </label>
                <div className="w-full">
                  <SprayDateHr
                    id="secondSpray"
                    dbKey="secondSpray"
                    minDate={secondSprayMinDate}
                    maxDate={secondSprayMaxDate}
                    datesOnlyInMs={secondSprayDatesMs}
                    selectable={state.firstSpray}
                    spray={state.secondSpray}
                    setUserSpray={(secondSpray) =>
                      dispatch({
                        type: "setSecondSpray",
                        secondSpray,
                      })
                    }
                    placeholderText="Select 2nd Spray"
                    modelName={modelData.title}
                    tooltip={`Clear Second Spray`}
                  ></SprayDateHr>
                </div>
              </div>

              {/* Third Spray Date */}
              <div className="mt-8">
                <label
                  htmlFor="first_spray"
                  className="block text-sm font-medium text-left text-gray-700"
                >
                  Third Spray
                </label>
                <div className="w-full">
                  <SprayDateHr
                    id="thirdSpray"
                    dbKey="thirdSpray"
                    minDate={thirdSprayMinDate}
                    maxDate={thirdSprayMaxDate}
                    datesOnlyInMs={thirdSprayDatesMs}
                    selectable={state.secondSpray}
                    spray={state.thirdSpray}
                    setUserSpray={(thirdSpray) =>
                      dispatch({
                        type: "setThirdSpray",
                        thirdSpray,
                      })
                    }
                    placeholderText="Select 3rd Spray"
                    modelName={modelData.title}
                    tooltip={`Clear Third Spray`}
                  ></SprayDateHr>
                </div>
              </div>

              <div className="flex justify-end mt-8 lg:mt-16">
                <button
                  type="button"
                  aria-label="Cancel action and close modal"
                  onClick={handleCancel}
                  className="inline-flex items-center px-4 py-2 mr-4 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500"
                >
                  Cancel
                </button>

                <button
                  type="button"
                  aria-label="Add Block"
                  onClick={handleSuccess}
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-secondary-600 hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500"
                >
                  {isEdit ? "Update" : "Create"}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </ModelLayoutNew>
  );
}
